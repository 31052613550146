import httpRequst from "../utils/request";
// 开票列表
export const invoiceList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/invoice/unbill-order`, params)
}
// 未开票的数量和金额
export const invoiceAmount = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/invoice/unbill-amount`, params)
}
// 获取发票信息 

export const getInvoiceInfo = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/invoice/my`, params)
}
// 索取发票 api/invoice/apply
export const invoiceApply= function(params) {
    return httpRequst.post(`${process.env.VUE_APP_BASEURL}/api/invoice/apply`, params)
}
// 发票管理列表
export const invoiceOrderList = function(params) {
    return httpRequst.get(`${process.env.VUE_APP_BASEURL}/api/invoice`, params)
}


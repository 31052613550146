<template>
	<div class="pl-confirm-wrap">

		<p>您选取了{{ ids.length }}条订单开具发票,开票金额：<span style="color: #D9262C">￥{{ amount }}</span></p>
		<div class="pl-info-box">
			<div class="pl-nav-box">
				<span class="primary-color pl-title">专用发票</span>
			</div>
			<div class="pl-row-box">
				<span>开具类型：</span>
				<span>企业增值税发票</span>
			</div>
			<div class="pl-row-box">
				<span>发票抬头：</span>
				<span>{{ info.headers }}</span>
			</div>
			<div class="pl-row-box">
				<span>税务登记号：</span>
				<span>{{ info.tax_number }}</span>
			</div>
			<div class="pl-row-box">
				<span>开户行名称：</span>
				<span>{{ info.account_bank_name }}</span>
			</div>
			<div class="pl-row-box">
				<span>基本开户账号：</span>
				<span>{{ info.account_bank }}</span>
			</div>
			<div class="pl-row-box">
				<span>注册场所地址：</span>
				<span>{{ info.reg_place }}</span>
			</div>
			<div class="pl-row-box">
				<span>注册固定电话：</span>
				<span>{{ info.reg_tel }}</span>
			</div>
			<div class="pl-row-box">
				<span>发票性质：</span>
				<el-radio v-model="param.medium_type" label="1">电子</el-radio>
				<el-radio v-model="param.medium_type" label="2">纸质</el-radio>
			</div>
			<div class="pl-row-box pl-flex-start" v-if="param.medium_type == 2">
				<span>收件地址：</span>
				<el-input v-model="param.receipt_info" placeholder="请输入内容" maxlength="500" show-word-limit></el-input>
			</div>
			<div class="pl-row-box pl-flex-start" v-if="param.medium_type == 1">
				<span>邮箱地址：</span>
				<el-input v-model="param.receipt_info" placeholder="请输入内容" maxlength="50" show-word-limit></el-input>
			</div>
			<!-- <div class="pl-row-box pl-flex-start" v-if="param.medium_type == 2">
					<span>收件人：</span>
					<el-input placeholder="请输入内容" maxlength="50"></el-input>
			</div> -->
		</div>
		<div class="pl-btn-box pl-flex">
			<el-button type="primary" @click="invoiceApply">索取发票</el-button>
			<el-button type="primary" plain @click="$router.go(-1)">上一步</el-button>

		</div>
	</div>

</template>
<script>
import {getInvoiceInfo, invoiceApply} from '@/api/invoice'

export default {
	data() {
		return {
			radio: '2',
			info: {},
			param: {
				business_type: 1,
				medium_type: '2',
				receipt_info: ''
			},
			ids: [],
			amount: sessionStorage.getItem('invoice_amount') || ''
		}
	},
	methods: {
		invoiceApply() {
			if (!Object.keys(this.info).length) return
			if (!this.param.receipt_info) {
				this.$message.error(this.param.medium_type === 1 ? '邮箱地址不能为空' : '收件地址不能为空')
				return;
			}
			let {account_bank, account_bank_name, headers, reg_place, reg_tel, tax_number} = this.info
			let param = this.param
			param.order_ids = this.ids
			param.header = headers
			param.tag_reg_number = tax_number
			param.bank = account_bank_name
			param.account = account_bank
			param.reg_address = reg_place
			param.reg_tel = reg_tel
			invoiceApply(this.param).then(() => {
				this.$message.success('申请成功')
				this.$router.push('/user/invoice')
			})
		}
	},
	created() {
		let ids = sessionStorage.getItem('invoice_list')
		if (ids) {
			this.ids = ids = JSON.parse(ids)
		}
		getInvoiceInfo().then(res => {
			this.info = res || {}
			console.log(res)
		})
	}
}
</script>
<style lang="scss" scoped>
.pl-confirm-wrap {
	width: 100%;
	min-height: 100vh;
	background: #fff;
	padding: 30px;
}

.pl-info-box {
	width: 100%;
	margin: 30px auto;

	.pl-nav-box {
		font-size: 18px;
		margin: 20px 0;
	}

	.pl-row-box {
		margin: 38px 0;

		span:nth-of-type(1) {
			display: inline-block;
			width: 150px;
			text-align: right;
		}

		.el-input {
			width: 50%;
		}
	}

}

.pl-btn-box {
	width: 350px;
	margin: auto;
}
</style>
